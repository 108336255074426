<template>
  <v-container class="text-center">
    <v-row>
      <v-col class="text-center pa-md-5">
        <h4 class="text-h4 font-weight-regular">Checker - Details</h4>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col cols="12" xl="8" offset-xl="2">
        <v-card>
          <v-card-text v-if="checkerData">
            <v-simple-table :loading="loading">
              <template v-slot:default>
                <tbody class="text-right">
                  <tr v-if="checkerData.makerId && canShowUserInfo()">
                    <td class="font-weight-bold" width="200px">Maker ID</td>
                    <td class="text-left">
                      <v-btn
                        outlined
                        :to="{ name: 'user.view', params: { id: checkerData.makerId } }"
                      >
                        {{ checkerData.makerId }}
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-if="checkerData.checkerId && canShowUserInfo()">
                    <td class="font-weight-bold" width="200px">Checker ID</td>
                    <td class="text-left">
                      <v-btn
                        outlined
                        :to="{ name: 'user.view', params: { id: checkerData.checkerId } }"
                      >
                        {{ checkerData.checkerId }}
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold" width="200px">Verdict</td>
                    <td class="text-left">
                      <v-chip
                        small
                        class="white--text"
                        :color="
                          { APPROVED: 'success', REJECTED: 'red' }[checkerData.verdict] || 'info'
                        "
                        >{{ checkerData.verdict || "Pending" }}</v-chip
                      >
                    </td>
                  </tr>
                  <tr v-for="item in items" :key="item.name">
                    <td class="font-weight-bold">{{ item.name }}</td>
                    <td class="text-left text--monospaced pa-3">
                      {{ emptyChecker(item.value(checkerData)) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-divider class="my-2" />

            <v-expansion-panels v-model="panels" multiple>
              <v-expansion-panel :key="0" class="mb-2">
                <v-expansion-panel-header class="title"> Request Body </v-expansion-panel-header>
                <v-divider />
                <v-expansion-panel-content class="panel-body">
                  <pre class="pre-style">{{
                    checkerData.requestBody || "No request data available!"
                  }}</pre>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel :key="1">
                <v-expansion-panel-header class="title"> Response Body </v-expansion-panel-header>
                <v-divider />
                <v-expansion-panel-content class="panel-body">
                  <pre class="pre-style">{{
                    checkerData.responseBody || "No response data available!"
                  }}</pre>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-divider class="my-2" />

            <VerdictButton v-if="!checkerData.verdict" :id="requestId" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { CHECKER_GET } from "@/constants/api";
import VerdictButton from "@/views/components/Checker/VerdictButton.vue";

export default {
  name: "CheckerDetails",
  metaInfo: { title: "Checker Details" },
  components: {
    VerdictButton,
  },
  data() {
    return {
      loading: false,
      checkerData: null,
      reqBody: false,
      resBody: false,
      panels: [],
      items: [
        { name: "Request ID", value: (item) => item.id },
        {
          name: "Created At",
          value: (item) => moment(item.createdAt).format("DD MMM, YYYY \n hh:mm:ss A"),
        },
        { name: "Method", value: (item) => item.method },
        { name: "Url", value: (item) => item.url },
      ],
    };
  },
  computed: {
    requestId() {
      return this.$route.params.id;
    },
  },
  watch: {
    panels(to) {
      this.reqBody = to.includes(0);
      this.resBody = to.includes(1);
      if (this.reqBody && !this.checkerData?.requestBody) {
        this.fetchCheckerDetails();
      } else if (this.resBody && !this.checkerData?.responseBody) {
        this.fetchCheckerDetails();
      }
    },
  },
  mounted() {
    if (this.requestId) {
      this.fetchCheckerDetails();
    }
  },
  methods: {
    async fetchCheckerDetails() {
      this.loading = true;
      try {
        const response = await this.$axios.get(
          `${CHECKER_GET}?requestId=${this.requestId}&requestBody=${this.reqBody}&responseBody=${this.resBody}`
        );
        if (response.data.code === 0) {
          this.checkerData = response.data.data;
          this.prettifyData();
          return;
        }
        throw new Error(response.data.message);
      } catch (err) {
        this.$iziToast.showError(err);
        this.$router.back();
      } finally {
        this.loading = false;
      }
    },
    prettifyData() {
      if (this.checkerData.requestBody) {
        try {
          this.checkerData.requestBody = JSON.stringify(
            JSON.parse(this.checkerData.requestBody),
            null,
            4
          );
        } catch (e) {
          //
        }
      }
      if (this.checkerData.responseBody) {
        try {
          this.checkerData.responseBody = JSON.stringify(
            JSON.parse(this.checkerData.responseBody),
            null,
            4
          );
        } catch (e) {
          //
        }
      }
    },
  },
};
</script>
<style lang="scss">
.panel-body {
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
}
</style>
<style scoped lang="scss">
.panel-body {
  //min-height: 100px;
  padding: 0.5rem;
  .pre-style {
    text-align: left !important;
    border: 1px solid #ccc;
    background: #eee;
    padding: 10px;
    border-radius: 5px;
    // wrap
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
}
</style>
